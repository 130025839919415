import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { CouponTypeEnum } from 'common/enums/CouponTypeEnum'
import { Coupon } from 'common/types/CouponType'
import { ProductInfoInterface } from 'common/types/entities/ProductInterface'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import { getLocale } from 'client/reducers/pageReducer'
import { usePage } from 'publisher/store'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemWithoutLeftOffsetUi from '../ui/PricePlanItemWithoutLeftOffsetUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductProps {
  productInfo?: ProductInfoInterface
  coupon?: Coupon
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  mobileNameColor?: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

function Product({
  productInfo,
  coupon,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: ProductProps) {
  const { t } = useTranslation(['common', 'publisher'])
  const locale = usePage(getLocale)
  const needTotal =
    productInfo && productInfo.name && (productInfo.taxAmount || coupon)

  return (
    <PricePlanUi>
      {productInfo && productInfo.name && (
        <>
          <PricePlanItemWithoutLeftOffsetUi>
            <PricePlanNameUi
              fontSize={nameFontSize}
              mobileFontSize={mobileNameFontSize}
              fontStyle={nameFontStyle}
              mobileFontStyle={mobileNameFontStyle}
              fontWeight={nameFontWeight}
              mobileFontWeight={mobileNameFontWeight}
              fontFamily={nameFontFamily}
              mobileFontFamily={mobileNameFontFamily}
              lineHeight={nameLineHeight}
              mobileLineHeight={mobileNameLineHeight}
              color={nameColor}
              mobileColor={mobileNameColor}
            >
              {productInfo.name}
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
                mobileColor={mobileDescriptionColor}
              >
                {productInfo.variantDescription}
              </PricePlanDescriptionUi>
            </PricePlanNameUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {productInfo.currency}
              </CurrencyBadgeUi>
              {getReadablePrice(
                productInfo.priceWithoutTaxAndCurrency,
                productInfo.currency,
                locale,
              )}
            </PricePlanAmountUi>
          </PricePlanItemWithoutLeftOffsetUi>
          {coupon && (
            <>
              <PricePlanItemWithoutLeftOffsetUi>
                <PricePlanDescriptionUi
                  fontSize={descriptionFontSize}
                  mobileFontSize={mobileDescriptionFontSize}
                  fontStyle={descriptionFontStyle}
                  mobileFontStyle={mobileDescriptionFontStyle}
                  fontWeight={descriptionFontWeight}
                  mobileFontWeight={mobileDescriptionFontWeight}
                  fontFamily={descriptionFontFamily}
                  mobileFontFamily={mobileDescriptionFontFamily}
                  lineHeight={descriptionLineHeight}
                  mobileLineHeight={mobileDescriptionLineHeight}
                  color={descriptionColor}
                  mobileColor={mobileDescriptionColor}
                  data-test-id={`offer-price-product-${productInfo.name}`}
                >
                  {t('components.offer_price.one_shots_summary.discount')}
                  {coupon.discountType === CouponTypeEnum.Percent &&
                    ` (-${coupon.discountAmount}%)`}
                </PricePlanDescriptionUi>
                <PricePlanAmountUi
                  fontSize={amountFontSize}
                  mobileFontSize={mobileAmountFontSize}
                  fontStyle={amountFontStyle}
                  mobileFontStyle={mobileAmountFontStyle}
                  fontWeight={amountFontWeight}
                  mobileFontWeight={mobileAmountFontWeight}
                  fontFamily={amountFontFamily}
                  mobileFontFamily={mobileAmountFontFamily}
                  lineHeight={amountLineHeight}
                  mobileLineHeight={mobileAmountLineHeight}
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  <CurrencyBadgeUi
                    color={amountColor}
                    mobileColor={mobileAmountColor}
                  >
                    {productInfo.currency}
                  </CurrencyBadgeUi>
                  {` -${getReadablePrice(
                    coupon.discountType === CouponTypeEnum.Fixed
                      ? centsToEuro(coupon.discountAmount)
                      : // percentage to currency value conversion
                        productInfo.priceWithoutTaxAndCurrency *
                          (coupon.discountAmount / 100),
                    productInfo.currency,
                    locale,
                  )}`}
                </PricePlanAmountUi>
              </PricePlanItemWithoutLeftOffsetUi>
            </>
          )}
          {!!productInfo.taxAmount && (
            <PricePlanItemWithoutLeftOffsetUi>
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
                mobileColor={mobileDescriptionColor}
              >
                {t('components.offer_price.vat')}
              </PricePlanDescriptionUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {productInfo.currency}
                </CurrencyBadgeUi>
                {getReadablePrice(
                  productInfo.taxAmount,
                  productInfo.currency,
                  locale,
                )}
              </PricePlanAmountUi>
            </PricePlanItemWithoutLeftOffsetUi>
          )}
        </>
      )}
      {!!needTotal && (
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            mobileColor={mobileDescriptionColor}
          >
            {t('components.offer_price.total')}
          </PricePlanDescriptionUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <CurrencyBadgeUi
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              {productInfo.currency}
            </CurrencyBadgeUi>
            {getReadablePrice(
              productInfo.netPrice,
              productInfo.currency,
              locale,
            )}
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
      )}
    </PricePlanUi>
  )
}
export default Product
