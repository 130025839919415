import Rollbar from 'rollbar'
import { useRollbar } from '@rollbar/react'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { getIsMercadoPagoPaymentMethod } from 'common/components/entities/PaymentMethods/utils/creditCardsUtils'
import { PaymentFormStepTypeEnum } from 'common/enums/PaymentFormStepTypeEnum'
import { mercadoCreditCardTypes } from 'common/enums/PaymentMethodEnum'
import { getActivePaymentMethod } from 'publisher/reducers/paymentReducer'
import loadScript from 'publisher/utils/loadScript'
import {
  typedPageSelectors,
  useManagement,
  usePage,
  usePayment,
} from '../store'
import managementSelectors from '../store/management/managementSelectors'
import paymentSelectors, {
  getMercadoPagoPublicKey,
  getPaymentMethods,
} from '../store/payment/paymentSelectors'
import { useAreInstallmentsVisible } from './mercadoPago/hooks/useAreInstallmentsVisible'
import {
  MercadoPagoCardFormInterface,
  MercadoPagoFieldErrorsInterface,
  MercadoPagoInterface,
} from './mercadoPago/types/MercadoPagoInterface'
import { initCardForm } from './mercadoPago/utils/initCardForm'
import { useCardErrors } from './shared/hooks/useCardErrors'
import { useFinalDigitalProductPrice } from './shared/hooks/useFinalDigitalProductPrice'
import { useFinalPhysicalProductPrice } from './shared/hooks/useFinalPhysicalProductPrice'

type InstallmentsParams = {
  amount: string
  locale?: string
  bin: string
  processingMode: string
}

export type MercadoPago = {
  cardForm: (e: any) => any
  getInstallments: (params: InstallmentsParams) => Promise<any>
}

export type MercadoPagoServerError = {
  cause: { code: string; description: string }[]
  error: string
  message: string
  status: number
}

interface Constructable<T> {
  new (key: string, options?: { locale: string }): T
}

declare global {
  interface Window {
    MercadoPago: Constructable<MercadoPago>
    cardPaymentBrickController: {
      unmount: () => void
      getFormData: () => {
        installments: number
        issuer_id: string
        payer: {
          email: string
          identification: {
            type: string
            number: string
          }
        }
        payment_method_id: string
        token: string
        transaction_amount: number
      }
    }
    Rollbar?: Rollbar
  }
}

const defaultErrors: MercadoPagoFieldErrorsInterface = {
  cardNumber: '',
  cardExpiration: '',
  cardholderName: '',
  identificationNumber: '',
  securityCode: '',
}

const MercadoPagoContext = createContext<MercadoPagoInterface>(
  {} as MercadoPagoInterface,
)

export const useMercadoPago = () => useContext(MercadoPagoContext)

export function MercadoPagoProvider(
  props: React.PropsWithChildren<Record<string, never>>,
) {
  const rollbar = useRollbar()
  const { t } = useTranslation('publisher')
  const paymentMethods = usePayment(getPaymentMethods)
  const activePaymentMethod = usePayment(getActivePaymentMethod)
  const mercadoPagoPublicKey = usePayment(getMercadoPagoPublicKey)
  const [mercadoPago, setMercadoPago] = useState<MercadoPago | null>(null)
  const [cardForm, setCardForm] = useState<MercadoPagoCardFormInterface | null>(
    null,
  )
  const { addError, resetError, resetErrors, errors } =
    useCardErrors<MercadoPagoFieldErrorsInterface>(defaultErrors)
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const digitalProductPrice = useFinalDigitalProductPrice()
  const physicalProductPrice = useFinalPhysicalProductPrice()
  const areInstallmentsVisible = useAreInstallmentsVisible()
  const priceAmount = digitalProductPrice ?? physicalProductPrice

  const twoStepPaymentFormStepType = usePage(p =>
    typedPageSelectors.getVisibleTwoStepPaymentFormOptInStep(p, isDesktop),
  )
  const paymentStep = usePayment(paymentSelectors.getTwoStepPaymentFormStepType)
  const isTwoStepOptInStepVisible =
    !!twoStepPaymentFormStepType.length &&
    paymentStep === PaymentFormStepTypeEnum.STEP_OPT_IN
  const isMercadoPaymentActive =
    getIsMercadoPagoPaymentMethod(activePaymentMethod)

  useEffect(() => {
    if (
      mercadoPago &&
      isMercadoPaymentActive &&
      priceAmount !== null &&
      !isTwoStepOptInStepVisible
    ) {
      const cf = initCardForm(
        mercadoPago,
        priceAmount,
        addError,
        resetError,
        t,
        rollbar,
      )
      setCardForm(cf)

      return () => {
        try {
          cf.unmount()
        } catch (err) {
          rollbar.error('failed to unmount mercado pago card form', { err })
        }
      }
    }
  }, [
    mercadoPago,
    isMercadoPaymentActive,
    priceAmount,
    isTwoStepOptInStepVisible,
  ])

  useEffect(() => {
    if (
      mercadoCreditCardTypes.some(paymentMethod =>
        paymentMethods.includes(paymentMethod),
      ) &&
      mercadoPagoPublicKey &&
      !isTwoStepOptInStepVisible
    ) {
      loadScript(
        process.env.MERCADO_PAGO_API_URL as string,
        'mercado-pago',
        async () => {
          const mp = new window.MercadoPago(mercadoPagoPublicKey)
          setMercadoPago(mp)
        },
      )
    }
  }, [isTwoStepOptInStepVisible])

  const generatePaymentData = useCallback(async () => {
    if (cardForm) {
      resetErrors()
      await cardForm.createCardToken()
      return cardForm.getCardFormData()
    }
  }, [cardForm])

  return (
    <MercadoPagoContext.Provider
      value={{
        cardForm,
        errors,
        resetError,
        generatePaymentData,
        areInstallmentsVisible,
      }}
    >
      <form id="form-checkout">{props.children}</form>
    </MercadoPagoContext.Provider>
  )
}
